import styled from "styled-components";
import {observer} from "mobx-react-lite";
import AppMenu from "./AppMenu";
import UserMenu from "./UserMenu";
import NotificationMenu from "./NotificationMenu";
import {useAppStore} from "../../providers/RootStoreProvider";

const QuickNav = observer(() => {
    const {user} = useAppStore()
    const url = user?.isStudent() ? "https://intra.laplateforme.io/": "https://intra-adm.laplateforme.io/"
    return <QuickNavStyled>
        <IntranetButton href={url} target={"_blank"}>Intranet</IntranetButton>
        <AppMenu />
        <NotificationMenu />
        <UserMenu />
    </QuickNavStyled>
})


const IntranetButton = styled.a`
    display: flex;    
    padding: 0 15px;
    height: 40px;
    background-color: #2482b0;
    color: #fff;
    border-radius: 10px;
    align-items: center;
    font-size: 15px;
    font-weight: 600;
    text-decoration: none;
`

const QuickNavStyled = styled.div`
    position: relative;
    display: flex;
    height: 70px;
    align-items: center;
    justify-content: space-between;
    gap: 25px;
`

export default QuickNav
